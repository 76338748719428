import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'Sobre',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'Contatos',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/profile/account',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/views/NotificationView.vue')
  },
  {
    path: '/subjects',
    name: 'Disciplinas',
    component: () => import('@/views/SubjectsView.vue')
  },
  {
    path: '/aula/:idaula',
    name: 'Aula',
    component: () => import('@/views/AulaView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  },
  {
    path: '/:pathMatch(.*)', 
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
