import SubjectService from '../../services/SubjectService'

const state = () => ({
    subjectLoading: false,
    subjectMoreLoading: false,
    subject: [],
    subjects: JSON.parse(localStorage.getItem('subjects')) || [],
    pages: JSON.parse(localStorage.getItem('pages')) || 0,
    page: JSON.parse(localStorage.getItem('page')) || 1,
})
  
const mutations = {
    SET_SUBJECT: (state, subject) => {
        state.subject = subject;
    },
    SET_SUBJECTS: (state, payload) => {
        state.subjects = payload;
        localStorage.setItem('subjects', JSON.stringify(payload));
    },
    SET_MORE_SUBJECTS: (state, payload) => {
        state.subjects = [...state.subjects, ...payload.subjects];
        state.pages = payload.pages;
        state.page = payload.pageNumber;
        localStorage.setItem('subjects', JSON.stringify(state.subjects));
        localStorage.setItem('pages', JSON.stringify(payload.pages));
        localStorage.setItem('page', JSON.stringify(payload.pageNumber));
    },
    SET_LOADING_SUBJECT: (state, payload) => {
        state.subjectLoading = payload
    },
    SET_LOADING_MORE_SUBJECT: (state, payload) => {
        state.subjectMoreLoading = payload
    },
    ATUALIZAR_DISCIPLINA(state, SubjectAtualizada) {
        const index = state.subjects.findIndex(d => d.id === SubjectAtualizada.id);
        
        if (index !== -1) {
          state.subjects.splice(index, 1, SubjectAtualizada);
          localStorage.setItem('subjects', JSON.stringify(state.subjects));
        }
    },
}

const actions = {
    async fetchSubject({commit}, {idSubject = null, idAula = null}){
        try {
            commit('SET_LOADING_SUBJECT', true);
            const response = await SubjectService.getOne(idAula, idSubject);
            commit('SET_SUBJECT', response.data);
            if(idAula !== null){ commit('SET_AULA', response.data.aulas.find(aula => aula.id == idAula));}
            commit('SET_LOADING_SUBJECT', false);
        } catch (error) {
            commit('SET_LOADING_SUBJECT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchMoreSubjects({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes'}){
        try {
            commit('SET_LOADING_MORE_SUBJECT', true);
            const response = await SubjectService.get(keyword, pageNumber, sortBy);
            commit('SET_MORE_SUBJECTS', response.data);
            commit('SET_LOADING_MORE_SUBJECT', false);
        } catch (error) {
            commit('SET_LOADING_MORE_SUBJECT', false);
        }
    },
    async carregarAulasDaSubject({commit, rootState, dispatch}, Subject){
        try {
            if(Subject.aulas !== undefined) return
            commit('SET_LOADING_AULA', true);
            const response = await SubjectService.getOne(null, Subject.id);
            const aulas = response.data.aulas

            for (const aula of aulas) {
                aula.dados = await dispatch('fetchDadosThumbnails', { idAula: aula.id, videoId: aula.link })
            }

            const subject = rootState.subjects.subjects.find(d => d.id === Subject.id);

            if (subject) {
                subject.aulas = aulas;
                subject.porcentagem = response.data.porcentagem;
                commit('ATUALIZAR_DISCIPLINA', subject);
            }

            rootState.subjects.subjects.map(subject => {
                if (subject.id === Subject.id) {
                    subject.aulas = aulas;
                }
            });

            commit('SET_LOADING_AULA', false);
        } catch (error) {
            commit('SET_LOADING_AULA', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getSubject: state => state.subject,
    getSubjects: state => state.subjects,
    getLoadingSubject: state => state.subjectLoading,
    getLoadingMoreSubject: state => state.subjectMoreLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};