const state = () => ({
    isMenuOpen: false,
    authenticateBeforeCheckout: true,
    hideScrollBar: false,
    alerts: [],
    currency: 'R$',
    storeName: 'Platform',
    showModalSearch: false
})
  
const mutations = {
    TOGGLE_MENU(state, payload) {
        state.hideScrollBar = payload
        state.isMenuOpen = payload
    },
    SET_ALERT(state, alert){
        state.alerts.unshift(alert);
        setTimeout(() => {
            state.alerts.pop();
        }, 5000);
    },
    SET_SHOW_MODAL_SEARCH(state, boolean){
        state.showModalSearch= boolean
    }
}

const getters = {
    getIsMenuOpen: state => state.isMenuOpen,
    getAlerts: state => state.alerts,
    getShowModalSearch: state => state.showModalSearch
}

export default {
    state,
    getters,
    mutations
};