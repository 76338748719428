import PeriodoService from '../../services/PeriodoService'

const state = () => ({
    periodosAtual: [],
    periodosDisciplina: [],
    lastIntervals: [],
})
  
const mutations = {
    SET_PERIODOS_ATUAIS: (state, periodos) => {
        state.periodosAtual = periodos;
    },
    SET_PERIODOS_DISCIPLINA: (state, periodos) => {
        state.periodosDisciplina = periodos;
    },
    SET_LAST_INTERVEALS: (state, intervals) => {
        state.lastIntervals = intervals;
    },
}

const actions = {
    async fetchPeriodosAtual({commit}, idAula){
        try {
            const response = await PeriodoService.get(idAula);
            commit('SET_PERIODOS_ATUAIS', response.data);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPeriodosSubject({commit}, idSubject){
        try {
            const response = await PeriodoService.getAll(idSubject);
            commit('SET_PERIODOS_DISCIPLINA', response.data);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    registrarPeriodos({commit, rootState}, periodos) {
        try{
            const aula = rootState.aulas.aula
            if(!aula || aula == []){
                commit('SET_ALERT', {
                    heading: 'error',
                    message: "Aula não encontrada"
                });
            }
            PeriodoService.insert({periodos: periodos, AulaId: aula.id});
        }catch(error){
            console.log( error)
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPeriodosAtual: state => state.periodosAtual,
    getPeriodosDisciplina: state => state.periodosDisciplina,
    getLastIntervals: state => state.lastIntervals,
}

export default {
    state,
    getters,
    mutations,
    actions
};