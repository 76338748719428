// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import product from './modules/product';
import notifications from './modules/notification';
import subjects from './modules/subject';
import aulas from './modules/aulas';
import periodos from './modules/periodos';
import category from './modules/category';

// Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        product,
        notifications,
        subjects,
        aulas,
        periodos,
        category
    }
})
