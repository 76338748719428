import axios from 'axios'

export default () => {

    return axios.create({
        baseURL: `${process.env.VUE_APP_URL}/api/`,
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            DataBase: `exemplo`
        }   
    })
}