import AulaService from '../../services/AulaService'
import axios from 'axios';

const state = () => ({
    aulaLoading: false,
    getLoadingAulaSearch: false,
    aula: [],
    aulasSearch: [],
    aulasAssistidas: JSON.parse(localStorage.getItem('aulasAssistidas')) || [],
    duration: 0,
    keyword: ''
})
  
const mutations = {
    SET_AULA: (state, aula) => {
        state.aula = aula;
    },
    SET_AULAS_SEARCH: (state, aulas) => {
        state.aulasSearch = aulas;
    },
    SET_LOADING_AULA: (state, payload) => {
        state.aulaLoading = payload
    },
    SET_LOADING_AULA_SEARCH: (state, payload) => {
        state.getLoadingAulaSearch = payload
    },
    SET_DURATION: (state, duration) => {
        state.duration = duration
    },
    SET_AULAS_ASSISTIDAS: (state, payload) => {
        state.aulasAssistidas = payload
        localStorage.setItem('aulasAssistidas', JSON.stringify(payload));
    },
}

const actions = {
    async fetchBusca({commit, dispatch}, { keyword = '', pageNumber = 1, sortBy = 'alfabetica', idCategory = null}){
        try {
            commit('SET_LOADING_AULA_SEARCH', true);
            const response = await AulaService.get(keyword, pageNumber, sortBy, idCategory);            
            for (const aula of response.data.aulas) {
                aula.dados = await dispatch('fetchDadosThumbnails', { idAula: aula.id, videoId: aula.link })
            }
            commit('SET_AULAS_SEARCH', response.data.aulas);
            commit('SET_LOADING_AULA_SEARCH', false);
        } catch (error) {
            console.warn(error)
            commit('SET_LOADING_AULA_SEARCH', false);
        }
    },
    async registrarVisualizacao({commit, rootState, dispatch}) {
        try{
            const aula = rootState.aulas.aula
            if(!aula || aula == []){
                commit('SET_ALERT', {
                    heading: 'error',
                    message: "Aula não encontrada"
                });
            }
            await AulaService.setAulaConcluida({AulaId: aula.id});
            dispatch('fetchSubject', {idAula: aula.id})

        }catch(error){
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
    async fetchVideosAssistidos({commit, dispatch}){
        try {
            commit('SET_LOADING_AULA', true);
            const response = await AulaService.getAllAulasAssistidas();
        
            for (const aula of response.data) {
                if(!aula.dados) aula.dados = await dispatch('fetchDadosThumbnails', { idAula: aula.id, videoId: aula.link })
            }
            commit('SET_AULAS_ASSISTIDAS', response.data);
      
            commit('SET_LOADING_AULA', false);
        } catch (error) {
            commit('SET_LOADING_AULA', false);
            commit('LOG_USER_OUT');
        }
    },
    async fetchDadosThumbnails({ commit }, conteudo) {
        const { videoId } = conteudo;
        // commit('SET_LOADING_AULA', true);
        
        const apiUrl = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`;
        try {
            const response = await axios.get(apiUrl);
            let thumbnailUrl = response.data;

            const seconds = thumbnailUrl.duration;

            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;

            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(remainingSeconds).padStart(2, '0');

            if(formattedHours > 0){
                thumbnailUrl.time = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            } else {
                thumbnailUrl.time = `${formattedMinutes}:${formattedSeconds}`;
            }
            // commit('SET_LOADING_AULA', false);
            
            return thumbnailUrl;
        } catch (error) {
            commit('SET_LOADING_AULA', false);
            console.error('Erro ao buscar a miniatura do vídeo:', error);
            return null;
        }
    }
}

const getters = {
    getAula: state => state.aula,
    getAulasAssistidas: state => state.aulasAssistidas,
    getAulasPesquisada: state => state.aulasSearch,
    getDuration: state => state.duration,
    getLoadingAula: state => state.aulaLoading,
    getLoadingAulaSearch: state => state.getLoadingAulaSearch
}

export default {
    state,
    getters,
    mutations,
    actions
};